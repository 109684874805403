import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styled from "styled-components"

const TitleWapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 4rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  h1 {
    font-size: 3rem;
    margin-bottom: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 7px;
    ::after {
      content: "";
      display: flex;
      flex-direction: row;
      width: 75%;
      margin: 0 auto;
      bottom: 0;
      height: 0.2rem;
      border: 0;
      background-color: #f5cd80;
      background: -webkit-gradient(
        linear,
        0 0,
        100% 0,
        from(transparent),
        to(transparent),
        color-stop(50%, #f5cd80)
      );
    }
  }

  h3 {
    font-size: 1rem;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding: 0.3rem;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }

  @media (min-width: 1281px) {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    h1 {
      font-size: 5rem;
    }
    h3 {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    h1 {
      font-size: 2.5rem;
      letter-spacing: 5px;
    }
    h3 {
      line-height: 1.3;
    }
  }

  @media (max-width: 360px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    h1 {
      font-size: 2rem;
    }
    h3 {
      line-height: 1.5;
    }
  }
`

const Button = styled(AniLink)`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1.3rem;
  margin-top: 0.5rem;
  background-color: #000;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  position: relative;
  margin: 0 auto;
  font-weight: 600;
  box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.75);
  background: rgb(247, 209, 134);
  background: radial-gradient(
    circle,
    rgba(247, 209, 134, 1) 0%,
    rgba(248, 211, 138, 1) 31%,
    rgba(224, 169, 74, 1) 78%
  );
`

const HeroTitle = props => {
  return (
    <>
      <TitleWapper>
        <h1>{props.title}</h1>
        <h3>{props.heroSub}</h3>
        <Button
          // cover
          // direction="right"
          // duration={1.5}
          // bg="#e0a94a"
          to="/articles"
          aria-label="Charity"
        >
          Read What's New
        </Button>
      </TitleWapper>
    </>
  )
}

export default HeroTitle
